import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useLocationMatch } from '@kaliber/routing'

import { CONTENT_TYPE_BIOGRAPHIES, CONTENT_TYPE_TAGS } from '/pages/Techblog'

import { routeMap } from '/routeMap'
import { useReportError } from '/machinery/ReportError'
import { useTranslate } from '/machinery/I18n'
import { useWithGroupedBlocksType } from '/machinery/useWithGroupedBlocksType'
import { resolveLinkObjectToHref } from '/machinery/link'

import { AlignContentRight, AlignContentArticle } from '/features/buildingBlocks/AlignContent'
import { WavemakersCta } from '/features/pageOnly/wavemakers/WavemakersCta'
import { ButtonLinkWithIcon } from '/features/buildingBlocks/Button'
import { ContainerSm, ContainerMd, ContainerLg, ContainerFlexibleLg } from '/features/buildingBlocks/Container'
import { Cta } from '/features/buildingBlocks/Cta'
import { CtaWithTitleAndButton, CtaWithTitleAndButtonTechblog } from '/features/buildingBlocks/CtaWithTitleAndButton'
import { DefinitionList } from '/features/buildingBlocks/DefinitionList'
import { Download } from '/features/buildingBlocks/Download'
import { ImageWithContent } from '/features/buildingBlocks/ImageWithContent'
import { EmployeeBiographies } from '/features/buildingBlocks/EmployeeBiographies'
import { InlineImage } from '/features/buildingBlocks/InlineImage'
import { Jobs } from '/features/buildingBlocks/Jobs'
import { Podcast } from '/features/buildingBlocks/Podcast'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { Quote } from '/features/buildingBlocks/Quote'
import { TechblogTags } from '/features/buildingBlocks/TechblogTags'
import { TextWithVideo } from '/features/buildingBlocks/TextWithVideo'
import { CtaWithRecruiter } from '/features/buildingBlocks/CtaWithRecruiter'
import { availableTileStyleContexts } from '/features/buildingBlocks/tile/Tile'
import { FaqList } from '/features/buildingBlocks/FaqList'
import { CtaToTraineeship } from '/features/pageOnly/traineeship/CtaToTraineeship'
import { IntroWithTitle } from '/features/pageOnly/IntroWithTitle'
import { Anchor } from '/features/buildingBlocks/Anchor'

import RewardCalculatorContent from '/features/buildingBlocks/reward-calculator/RewardCalculatorContent.universal'
import RewardCalculatorCta from '/features/buildingBlocks/reward-calculator/RewardCalculatorCta.universal'
import ImagesWithContentSlider from '/features/pageOnly/ImagesWithContentSlider.universal'
import EmbeddedCode from '/features/buildingBlocks/EmbeddedCode.universal'
import CookiePermissions from '/features/pageOnly/CookiePermissions.universal'
import TilesWithTextAndRelatedContent from '/features/buildingBlocks/TilesWithTextAndRelatedContent.universal'
import WavemakersVideoSelector from '/features/pageOnly/wavemakers/WavemakersVideoSelector.universal'
import FaqsTwoColumns from '/features/buildingBlocks/FaqsTwoColumns.universal'
import LinksBlock from '/features/buildingBlocks/LinksBlock.universal'
import FaqsOneColumn from '/features/buildingBlocks/FaqsOneColumn.universal'
import SkillsBanner from '/features/buildingBlocks/SkillsBanner.universal'
import RelatedContentGridHome from '/features/pageOnly/article/RelatedContentGridArticle.universal'
import ProcedureSlider from '/features/buildingBlocks/ProcedureSlider.universal'
import VideoLarge from '/features/buildingBlocks/VideoLarge.universal'
import QuickLinks from '/features/buildingBlocks/QuickLinks.universal'
import SimpleContentCards from '/features/pageOnly/SimpleContentCards.universal'
import TechblogSlider from '/features/buildingBlocks/TechblogSlider.universal'
import VideoPreview from '/features/pageOnly/wavemakers/VideoPreview.universal'
import VideoPreviewAnchor from '/features/pageOnly/wavemakers/Anchor.universal'
import FactsAndFigures from '/features/buildingBlocks/FactsAndFigures.universal'

import styles from './Content.css'

export function ContentDefault({ content }) {
  return (
    <ContentBase
      className={styles.componentDefault}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)

          return <ContentItemDefault key={`content__${i}`} {...{ data, containerProps }} />
        })
      }
    />
  )
}

export function ContentSimple({ content, vakgebied = undefined, cookieConfiguration = undefined }) {
  return (
    <ContentBase
      className={styles.componentSimple}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)

          return <ContentItemSimple key={`content__${i}`} {...{ data, vakgebied, containerProps, cookieConfiguration }} />
        })
      }
    />
  )
}

export function ContentFaq({ content, vakgebied = undefined }) {
  return (
    <ContentBase
      className={styles.componentFaq}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)

          return <ContentItemFaq key={`content__${i}`} {...{ data, containerProps, vakgebied }} />
        })
      }
    />
  )
}

export function ContentArticle({ content, vakgebied = undefined }) {
  return (
    <ContentBase
      className={styles.componentArticle}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)

          return <ContentItemArticle key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

export function ContentTechblog({ content, vakgebied = undefined }) {
  return (
    <ContentBase
      className={styles.componentTechblog}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)

          return <ContentItemTechblog key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

export function ContentPanel({ content, vakgebied = undefined }) {
  return (
    <ContentBase
      className={styles.componentPanel}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)

          return <ContentItemPanel key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

export function ContentJobAlert({ content, vakgebied = undefined }) {
  return (
    <ContentBase
      className={styles.componentJobAlert}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)
          return <ContentItemJobAlert key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

export function ContentTopic({ content, vakgebied = undefined }) {
  return (
    <ContentBase
      className={styles.componentTopic}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)
          return <ContentItemTopic key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

export function ContentWavemakers({ content, vakgebied = undefined, layoutClassName = undefined }) {
  return (
    <ContentBase
      className={cx(styles.componentWavemakers, layoutClassName)}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)
          return <ContentItemWavemakers key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

export function ContentSubvakgebied({ content, vakgebied = undefined, layoutClassName = undefined }) {
  return (
    <ContentBase
      className={cx(styles.componentSubvakgebied, layoutClassName)}
      {...{ content }}
      renderContentBlocks={contentWithGroupedBlocks =>
        contentWithGroupedBlocks.map((data, i) => {
          const containerProps = createContentItemContainerProps(data)
          return <ContentItemSubvakgebied key={`content__${i}`} {...{ data, vakgebied, containerProps }} />
        })
      }
    />
  )
}

function createContentItemContainerProps(data) {
  return {
    layoutClassName: cx(styles.item, styles[`item--${data._type}Layout`], styles.itemLayout),
    dataX: `flexible-content-${data._type}`
  }
}

function ContentBase({ content, renderContentBlocks, className = undefined }) {
  const contentWithGroupedBlocks = useWithGroupedBlocksType(content ?? [])

  return (
    <div className={cx(styles.componentBase, className)}>
      {renderContentBlocks(contentWithGroupedBlocks)}
    </div>
  )
}

function ContentItemDefault({ containerProps, data }) {
  const { __ } = useTranslate()
  const reportError = useReportError()

  switch (data._type) {
    case 'introWithTitle': return (
      <ContainerLg styleContext='light' {...containerProps}>
        <IntroWithTitle title={data.title} introduction={data.introduction} />
      </ContainerLg>
    )
    case 'imageWithContent': return (
      <ContainerLg styleContext='light' {...containerProps}>
        <ImageWithContent image={data.image} title={data.title} blocks={data.blocks} employees={data.employees} internalOrExternalLink={data.internalOrExternalLink} />
      </ContainerLg>
    )
    case 'imagesWithContentSlides': return (
      <ContainerLg styleContext='light' {...containerProps}>
        <ImagesWithContentSlider items={data.items} />
      </ContainerLg>
    )
    case 'video': return (
      <ContainerLg {...containerProps}>
        <VideoLarge title={data?.title} url={data?.src} poster={data?.poster} />
      </ContainerLg>
    )
    case 'videoFullWidth': return (
      <Section dataX='video-full-width' {...containerProps}>
        <VideoLarge title={data?.title} url={data?.src} poster={data?.poster} />
      </Section>
    )
    case 'jobs':
      return data.jobs.length ? (
        <ContainerLg styleContext='light-alt' {...containerProps}>
          <Jobs
            title={__({ vakgebied: data.label })`vakgebied-related-jobs-title`}
            button={{
              label: __(Number(data?.totalHits), { count: data.totalHits })`vakgebied-related-jobs-cta`,
              href: data.href
            }}
            description={__`explain-jobs-text`}
            jobs={data.jobs}
          />
        </ContainerLg>
      ) : null
    case 'faqs': return (
      <ContainerLg styleContext='light-alt' {...containerProps}>
        <FaqsTwoColumns title={data?.title} description={data?.blocks} questions={data?.questions} />
      </ContainerLg>
    )
    case 'ctaWithRecruiter': return data.recruiters && (
      <ContainerLg styleContext='light-alt' {...containerProps}>
        <CtaWithRecruiter title={data.title} recruiters={data.recruiters} />
      </ContainerLg>
    )
    case 'blocks': return (
      <ContainerLg {...containerProps}>
        <AlignContentRight>
          <PortableTextMainContent value={data.blocks} />
        </AlignContentRight>
      </ContainerLg>
    )
    case 'quote': return (
      <Section dataX='quote' {...containerProps}>
        <Quote image={data.image} name={data?.source} quote={data?.text} />
      </Section>
    )
    case 'podcast': return (
      <ContainerSm {...containerProps}>
        <Podcast title={data.title} src={data.src} />
      </ContainerSm>
    )
    case 'image': return (
      <ContainerLg {...containerProps}>
        <InlineImage image={data} caption={data?.caption || data?.image?.caption} sizes='var(--container-max-width-lg)' />
      </ContainerLg>
    )
    case 'inlineImage': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <InlineImage image={data.image} caption={data?.caption || data?.image?.caption} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case 'cta': return (
      <ContainerLg {...containerProps}>
        <Cta image={data.image} title={data.title} label={data.label} link={data.internalOrExternalLink} />
      </ContainerLg>
    )
    case 'ctaWithTitleAndButton':
      return (
        <ContainerLg styleContext='light-alt' {...containerProps}>
          <CtaWithTitleAndButton title={data.title} link={data.internalOrExternalLink} />
        </ContainerLg>
      )
    case 'tiles': return (
      <ContainerFlexibleLg {...containerProps}>
        <RelatedContentGridHome tiles={data.tiles} availableStyleContexts={availableTileStyleContexts.filter(x => x !== 'light')} />
      </ContainerFlexibleLg>
    )
    case 'download': return data.file && (
      <ContainerLg {...containerProps}>
        <Download title={data.title} file={data.file} />
      </ContainerLg>
    )
    case 'procedureSlider': return (
      <Section dataX='procedure-slider' {...containerProps}>
        <ProcedureSlider steps={data.slides} />
      </Section>
    )
    case 'linksBlock': return (
      <ContainerLg {...containerProps}>
        <AlignContentRight>
          <LinksBlock links={data.links} title={data.title} />
        </AlignContentRight>
      </ContainerLg>
    )

    case 'textWithVideo': {
      const href = data?.internalOrExternalLink && resolveLinkObjectToHref(data?.internalOrExternalLink)

      return (
        <ContainerLg {...containerProps}>
          <TextWithVideo
            text={data.text}
            video={data.video}
            hideOverlay={data.hideOverlay}
            orientation={data.orientation}
            button={{
              href,
              label: data?.internalOrExternalLink?.label,
              targetSelf: data?.internalOrExternalLink?.targetSelf
            }}
          />
        </ContainerLg>
      )
    }
    case 'definitionList': return (
      <ContainerLg {...containerProps}>
        <AlignContentRight>
          <DefinitionList items={data.items} />
        </AlignContentRight>
      </ContainerLg>
    )
    case 'jobList':
      return data.jobs.length ? (
        <ContainerLg styleContext='light-alt' {...containerProps}>
          <Jobs
            title={data.title}
            description={data?.description}
            button={{ href: data.href, label: data?.internalOrExternalLink?.label }}
            jobs={data.jobs}
          />
        </ContainerLg>
      ) : null
    case 'skillsBanner': return (
      <ContainerLg {...containerProps}>
        <SkillsBanner />
      </ContainerLg>
    )
    case 'tilesWithTextAndRelatedContent': return (
      <ContainerLg {...containerProps}>
        <TilesWithTextAndRelatedContent
          label={data.label}
          blocks={data.blocks}
          tiles={data.tiles}
          availableTileStyleContexts={availableTileStyleContexts.filter(x => x !== 'light')}
        />
      </ContainerLg>
    )
    case 'simpleContentCards': return Boolean(data.items) && (
      <ContainerLg styleContext='light-alt' {...containerProps}>
        <SimpleContentCards title={data.title} description={data.description} items={data.items} />
      </ContainerLg>
    )

    case 'rewardCalculator' : return (
      <ContainerLg {...containerProps}>
        <RewardCalculatorContent content={data.content} link={data.internalLink} title={data.title} />
      </ContainerLg>
    )

    case 'rewardCalculatorCta' : return (
      <ContainerLg {...containerProps}>
        <AlignContentRight>
          <RewardCalculatorCta link={data.internalLink} title={data.title} />
        </AlignContentRight>
      </ContainerLg>
    )

    case 'ctaToTraineeship': return (
      <Section id='ctaToTraineeship' dataX='cta-to-traineeship' {...containerProps}>
        <CtaToTraineeship openCloseStatus={data.openCloseLabel} subtitle={data.subtitle} pills={data.properties} title={data.title} blocks={data.text} image={data.image} link={data.urlWithLabel} />
      </Section>
    )

    case 'factsAndFigures': return (
      <Section dataX='facts-and-figures' {...containerProps}>
        <FactsAndFigures properties={data.properties} />
      </Section>
    )

    case 'anchor': return (
      <Anchor id={data.id} />
    )

    case 'quickLinks': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <QuickLinks links={data.links} title={data.title} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case 'techblogSlider': return (
      <Section dataX='techblog-slider' {...containerProps}>
        <TechblogSlider slides={data.tiles} id='techblog-slider' />
      </Section>
    )

    case 'animatedTilesWithTextAndTitle': return undefined

    default: {
      reportError(new Error(`Unknown content type '${data._type}'`))
      return process.env.NODE === 'production' ? null : 'TODO: ' + data._type
    }
  }
}

function ContentItemSimple({ containerProps, data, vakgebied, cookieConfiguration }) {
  switch (data._type) {
    case 'blocks': return (
      <ContainerMd {...containerProps}>
        <PortableTextMainContent value={data.blocks} />
      </ContainerMd>
    )
    case 'cookiePermissions': return (
      <ContainerMd {...containerProps}>
        <CookiePermissions initialConfiguration={cookieConfiguration} />
      </ContainerMd>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied, cookieConfiguration }} />
    }
  }
}

function ContentItemFaq({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'blocks': return (
      <ContainerSm {...containerProps}>
        <PortableTextMainContent value={data.blocks} />
      </ContainerSm>
    )
    case 'faqs': return (
      <ContainerSm {...containerProps}>
        <FaqsOneColumn title={data?.title} description={data?.blocks} questions={data?.questions} />
      </ContainerSm>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }
}

function ContentItemTechblog({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'ctaWithTitleAndButton': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <CtaWithTitleAndButtonTechblog title={data.title} link={data.internalOrExternalLink} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case 'codeBlock': return data.showSmall ? (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <EmbeddedCode code={data?.codeInput?.code} language={data?.codeInput?.language} />
        </AlignContentArticle>
      </ContainerLg>
    ) : (
      <ContainerLg {...containerProps}>
        <EmbeddedCode code={data?.codeInput.code} language={data?.codeInput?.language} />
      </ContainerLg>
    )
    case 'podcast': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <Podcast title={data.title} src={data.src} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case CONTENT_TYPE_BIOGRAPHIES: return data.employees.length && (
      <ContainerLg {...containerProps}>
        <EmployeeBiographies employees={data?.employees} />
      </ContainerLg>
    )
    case CONTENT_TYPE_TAGS: return data?.tags?.length ? (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <TechblogTags tags={data.tags} />
        </AlignContentArticle>
      </ContainerLg>
    ) : null
    default: {
      return <ContentItemArticle {...{ containerProps, data, vakgebied }} />
    }
  }
}

function ContentItemArticle({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'blocks': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <PortableTextMainContent value={data.blocks} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case 'faqs': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <FaqsOneColumn title={data?.title} description={data?.blocks} questions={data?.questions} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case 'download': return data.file && (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <Download title={data.title} file={data.file} />
        </AlignContentArticle>
      </ContainerLg>
    )
    case 'quickLinks': return (
      <ContainerLg {...containerProps}>
        <AlignContentArticle>
          <QuickLinks links={data.links} title={data.title} />
        </AlignContentArticle>
      </ContainerLg>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }
}

function ContentItemWavemakers({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'wavemakersVideo': return (
      <ContainerLg {...containerProps}>
        <WavemakersVideoSelector {...{ data }} />
      </ContainerLg>
    )
    case 'techblogSlider': return (
      <Section dataX='techblog-slider' {...containerProps}>
        <TechblogSlider slides={data.tiles} id='techblog-slider' />
      </Section>
    )
    case 'videoPreview': return (
      <VideoPreviewContainer {...containerProps}>
        <VideoPreview preview={data.preview} />
      </VideoPreviewContainer>
    )
    case 'videoPreviewAnchor': return (
      <Section dataX='video-preview-anchor' {...containerProps}>
        <VideoPreviewAnchor state={data.anchor.state} />
      </Section>
    )
    case 'wavemakersCta': return (
      <ContainerSm dataX='wavemakers-cta' {...containerProps}>
        <WavemakersCta title={data.title} content={data.content} />
      </ContainerSm>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }
}

function ContentItemSubvakgebied({ containerProps, data, vakgebied }) {
  const { route, params } = useLocationMatch()
  const { __ } = useTranslate()

  switch (data._type) {
    case 'jobs':
      return data.jobs.length ? (
        <ContainerLg styleContext='light-alt' {...containerProps}>
          <Jobs
            title={__({ vakgebied: data.label })`vakgebied-related-jobs-title`}
            button={{
              label: getJobsButtonLabel(),
              href: data.href
            }}
            description={__`explain-jobs-text`}
            jobs={data.jobs}
          />
        </ContainerLg>
      ) : null
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }

  function getJobsButtonLabel() {
    return route(params).includes('generative-ai')
      ? __`vakgebied-view-it-jobs`
      : __(Number(data.totalHits), { count: data.totalHits })`vakgebied-related-jobs-cta`
  }
}

function ContentItemPanel({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'blocks': return (
      <Section dataX='blocks' {...containerProps}>
        <PortableTextMainContent value={data.blocks} />
      </Section>
    )
    case 'download': return data.file && (
      <Section dataX='download' {...containerProps}>
        <Download title={data.title} file={data.file} />
      </Section>
    )
    case 'internalLink': return data.file && (
      <Section dataX='internal-link-section' {...containerProps}>
        <ButtonLinkWithIcon
          dataX='link'
          label={data.label}
          href={determineDocumentPathSync({ document: data.ref, routeMap })}
        />
      </Section>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }
}

function ContentItemJobAlert({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'blocks': return (
      <ContainerSm {...containerProps}>
        <PortableTextMainContent value={data.blocks} />
      </ContainerSm>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }
}

function ContentItemTopic({ containerProps, data, vakgebied }) {
  switch (data._type) {
    case 'blocks': return (
      <Section {...containerProps}>
        <AlignContentArticle>
          <PortableTextMainContent value={data.blocks} />
        </AlignContentArticle>
      </Section>
    )
    case 'faqList': return (
      <Section {...containerProps}>
        <AlignContentArticle>
          <FaqList questions={data.questions} />
        </AlignContentArticle>
      </Section>
    )
    case 'image': return (
      <Section {...containerProps}>
        <InlineImage image={data} caption={data?.caption || data?.image?.caption} sizes='var(--container-max-width-lg)' />
      </Section>
    )
    case 'video': return (
      <Section {...containerProps}>
        <VideoLarge title={data?.title} url={data?.src} poster={data?.poster} />
      </Section>
    )
    default: {
      return <ContentItemDefault {...{ containerProps, data, vakgebied }} />
    }
  }
}

function VideoPreviewContainer({ children, dataX = undefined, layoutClassName = undefined }) {
  return (
    <div data-x={dataX} className={cx(styles.componentVideoPreviewContainer, layoutClassName)}>
      {children}
    </div>
  )
}

function Section({ children, id = undefined, dataX = undefined, styleContext = undefined, layoutClassName = undefined }) {
  return (
    <section
      data-x={dataX}
      data-style-context={styleContext}
      className={layoutClassName}
      {...{ id }}
    >
      {children}
    </section>
  )
}
