import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'

import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { AlignContent } from '/features/buildingBlocks/AlignContent'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { RewardCalculator } from '/features/buildingBlocks/reward-calculator/RewardCalculator'

import styles from './RewardCalculatorContent.css'

export function RewardCalculatorContent({ content, title, link }) {
  const benefitsPageRef = link?.ref && {
    url: determineDocumentPathSync({ document: link.ref, routeMap }),
    label: link.label
  }

  return (
    <div className={styles.component}>
      <AlignContent
        renderLeftColumn={({ layoutClassName }) => <PortableTextMainContent value={content} {...{ layoutClassName }} />}
        renderRightColumn={({ layoutClassName }) => <Calculator link={benefitsPageRef} {...{ title, layoutClassName }} />}
      />
    </div>
  )
}

function Calculator({ title, link, layoutClassName }) {
  return (
    <div className={cx(styles.componentCalculator, layoutClassName)}>
      {title && (
        <HeadingMd layoutClassName={styles.headingLayout} h={3} {...{ title }} />
      )}
      <RewardCalculator {...{ link }} />
    </div>
  )
}
