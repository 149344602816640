import styles from './AlignContent.css'

export function AlignContent({ renderLeftColumn = undefined, renderRightColumn = undefined }) {
  return (
    <div className={styles.component}>
      {renderLeftColumn && renderLeftColumn({ layoutClassName: styles.leftColumnLayout })}
      {renderRightColumn && renderRightColumn({ layoutClassName: styles.rightColumnLayout })}
    </div>
  )
}

export function AlignContentRight({ children }) {
  return (
    <div className={styles.componentRight}>
      {children}
    </div>
  )
}

export function AlignContentArticle({ children }) {
  return (
    <div className={styles.componentArticle}>
      {children}
    </div>
  )
}
